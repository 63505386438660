import React from 'react';
import Layout from '@/layouts';
import Home from '@/pages/home';
import './config';

function App() {
  return (
    <Layout>
      <Home></Home>
    </Layout>
  );
}

export default App;
