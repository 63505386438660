import './index.scss';
import LogoBrand from '@/components/logo-brand';
import LangBtn from '@/components/nav/components/LangBtn';
import { useState } from 'react';
import useMobile from '@/utils/isMobile';
import { useTranslation } from 'react-i18next';
import { config } from 'process';

function Nav() {
  const { t } = useTranslation();
  const { mobile } = useMobile();
  const btns = [
    { label: '中文', value: 'zh-CN' },
    { label: 'English', value: 'en-EN' },
  ];
  const [cuLang, setCuLang] = useState<string>('zh-CN');
  const onClickLang = (value: string) => {
    setCuLang(value);
  };
  const onClickBrand = () => {
    window.scrollTo(0, 0);
  };
  const goManagement = () => {
    // window.location.href = 'https://fe.tablera.com/passport/entrance';
  };
  return (
    <nav className="c-global-nav__wrapper">
      <div className="c-global-nav a-flex-box">
        <div className="c-global-nav-left">
          <LogoBrand brandSize={mobile ? 3 : 2} onClick={onClickBrand}></LogoBrand>
        </div>
        <div className="c-global-nav-right">
          <div className={'nav-management-btn'} onClick={goManagement}>
            <a href={window?.config?.ENTRANCE_PATH} rel="noreferrer">
              {t('nav.management')}
            </a>
          </div>
          {/*<div className="nav-lang-btns">*/}
          {/*    {*/}
          {/*        btns.map(lang => <LangBtn key={lang.value} {...lang} lang={cuLang} onClick={onClickLang}></LangBtn>)*/}
          {/*    }*/}
          {/*</div>*/}
        </div>
      </div>
    </nav>
  );
}
export default Nav;
