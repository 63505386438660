import { useMemo } from 'react';
import './index.scss';
import useMobile from '@/utils/isMobile';

interface IProps {
  src?: string;
  className?: string;
  size?: [number, number];
  original?: boolean;
  alt?: string;
  style?: object;
  baseX?: boolean;
}
function Photo(props: IProps) {
  const { src, className, size = [], original = true, alt = '', style = {}, baseX = true } = props;
  const classname = useMemo(() => {
    const _classname = ['a-photo'];
    if (className) {
      _classname.push(className);
    }
    return _classname;
  }, [className]);
  const imgStyles = useMemo(() => {
    return {
      width: size[0],
      height: size[1],
    };
  }, [size, baseX]);
  const styles = useMemo(() => {
    if (size) {
      let length = '';
      let padding = '';
      let lengthVal = '';
      let paddingVal = '';
      if (baseX) {
        length = 'width';
        length = 'paddingBottom';
        lengthVal = '100%';
        paddingVal = `calc(100% * (${size[1]} / ${size[0]}))`;
      } else {
        length = 'height';
        length = 'paddingright';
        lengthVal = '100%';
        paddingVal = `calc(100% * (${size[0]} / ${size[1]}))`;
      }

      return {
        [padding]: paddingVal,
        [length]: length,
      };
    }
    return {};
  }, [size, baseX]);
  const suffix = original ? '/original.webp' : '/q90.webp';
  return (
    <div className={classname.join(' ')} style={{ ...style, ...styles }}>
      <img className={'a-photo__img'} src={`${src}${suffix}`} alt={alt} style={imgStyles} />
    </div>
  );
}
export default Photo;
