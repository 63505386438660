export default {
    'nav.management': '管理入口',
    'common.brand.name': 'Tablera',
    'common.btn.introducer': '申请开通',
    'register.explanation.start': '用',
    'register.explanation.end': '连接餐厅和食客',
    'booking.card0.title': '实时的预订状态',
    'booking.card0.content': '使用比电话效率更高的方式，实时查看指定日期和餐次内各种预订状态的桌台',
    'booking.card1.title': '直观的桌台位置',
    'booking.card1.content': '面对电话中沟通桌台位置的情况，在小程序中可直观查看位置信息满足用户各种要求',
    'booking.card2.title': '真实有效的预订',
    'booking.card2.content': '通过支付订金确保「食客」和「餐厅」双方的权益保障，让预订的真实有效',
    'booking.card3.title': '快捷预订管理&提醒',
    'booking.card3.content': '在提交预订之后，在小程序中可以方便查看&管理预订订单以及收到即将到期的预订提醒',
    'deposit.card0.title': '直观的桌台分布管理',
    'deposit.card0.content': '一眼全览餐厅的桌台布局，提高操作人员管理桌台效率，尽可能避免操作问题发生 ',
    'deposit.card1.title': '记录食客个性化信息',
    'deposit.card1.content': '在食客来前&用餐过程中记录&备注个性化服务信息，让食客感受到宾至如归的服务',
    'deposit.card2.title': '便捷的订金计算管理',
    'deposit.card2.content': '提供方便快捷的订金计算管理，无论是预订变更订金变化，抑或是餐厅的财务处理',
    'deposit.card3.title': '及时的变更提醒',
    'deposit.card3.content': '在有「新预订」或者「预订变更」时，产品会在第一时间提供醒目和及时的提醒',
    'client.card0.brand.name': 'The Hall 会馆',
    'client.card0.brand.address': '成都市中纱帽街8号远洋太古里广东会馆',
    'client.card0.brand.des':
        '路易威登在中国的首家餐厅“路易威登会馆“以现代风格传承历史文脉，打造全新城市地标。“路易威登会馆“以季节性优质食材打造泛欧陆风菜单，具有明显的经典法式风味和地中海风味，赋予更为精细化、轻盈健康的现代烹调理念。',
    'client.card1.brand.name': '丘末茶研所',
    'client.card1.brand.address': '杭州市四眼井44号',
    'client.card1.brand.des':
        '丘未是一家以当代宋式美学为主题的新型茶艺术空间，以吧台模式为主导，集合了纯茶，盘饰甜品，以及茶酒于一身。在这里，再一次打开和贯通五感，使情绪自然游走，交融，相会。',
    'introducer.explanation.start': '用',
    'introducer.explanation.end': '连接餐厅和食客',
    'introducer.des': '实时&高效预订 + 便捷预订管理 + 食客个性化服务',
    'footer.explanation.text': '开启新的预订方式',
    'footer.record.text': '© 上海七曜信息科技有限公司. All rights reserved.  沪ICP备2021034572号',
    'footer.contact.label': '商务合作',
    'footer.contact.value': 'gary.gu@tablera.com',
};
