import LogoBrand from '@/components/logo-brand';
import { useTranslation } from 'react-i18next';
import './index.scss';
import PageModule from '@/components/page-component';
import useMobile from '@/utils/isMobile';

import LogoChao from '@/images/customer-logo/logo-chao.png';
import LogoChaoyue from '@/images/customer-logo/logo-chaoyue.png';
import LogoCumo from '@/images/customer-logo/logo-cumo.png';
import LogoDv from '@/images/customer-logo/logo-dv.png';
import LogoJing from '@/images/customer-logo/logo-jing.png';
import LogoFukusushi from '@/images/customer-logo/logo-fukusushi.png';
import LogoLinglong from '@/images/customer-logo/logo-linglong.png';
import LogoLv from '@/images/customer-logo/logo-lv.png';
import LogoMiyahato from '@/images/customer-logo/logo-miyahato.png';
import LogoNewwave from '@/images/customer-logo/logo-newwave.png';
import LogoNoborusushi from '@/images/customer-logo/logo-noborusushi.png';
import LogoWaragyo from '@/images/customer-logo/logo-waragyo.png';
import LogoYiqingfeng from '@/images/customer-logo/logo-yiqingfeng.png';
import LogoYue from '@/images/customer-logo/logo-yue.png';
import LogoYuelu from '@/images/customer-logo/logo-yuelu.png';
import LogoYuzuColorful from '@/images/customer-logo/logo-yuzu-colorful.png';
import LogoYuzu from '@/images/customer-logo/logo-yuzu.png';

const LOGO_GROUP = [
  LogoDv,
  LogoLinglong,
  LogoNewwave,
  LogoFukusushi,
  LogoMiyahato,
  LogoNoborusushi,
  LogoWaragyo,
  LogoYue,
  LogoChao,
  LogoChaoyue,
  LogoYuelu,
  LogoYuzu,
  LogoJing,
  LogoYiqingfeng,
  LogoCumo,
  LogoLv,
  LogoYuzuColorful,
];

function Customer() {
  const { t } = useTranslation();
  const { mobile } = useMobile();
  return (
    <div className={'module-customer page-module'}>
      <div className="module-customer-logo-group">
        {LOGO_GROUP.map((logo) => (
          <div className="module-customer-logo-wrap" key={logo}>
            <img className="module-customer-logo" src={logo} />
          </div>
        ))}
      </div>
    </div>
  );
}
// export default Customer;
export default (props: { title?: string; des?: string; className?: string }) => (
  <PageModule {...props} overallWidth children={<Customer></Customer>}></PageModule>
);
