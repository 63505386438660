import PageModule from '@/components/page-component';
import Logo from '@/components/logo';
import './index.scss';
import { useTranslation } from 'react-i18next';
import Tag from '@/components/nav/components/Tag';
import Button from '@/components/button';
import Photo from '@/components/photo';
import Bubble from '@/components/bubble';
import useMobile from '@/utils/isMobile';
import { useEffect, useMemo } from 'react';

function Register() {
  const { t } = useTranslation();
  const { mobile } = useMobile();

  // 0: pc 1: mobile;
  const img = [
    {
      bg: { url: 'https://cdn.tablera.com/md5/4f2ed3842e9cf779151476d7ad13e895.png', size: [] },
      home: { url: 'https://cdn.tablera.com/project/homepage/asset/images/register.png', size: [1294, 724] },
    },
    {
      bg: { url: 'https://cdn.tablera.com/md5/193a524e1fd33234cecdb1a93399a2ff.png', size: [] },
      home: { url: 'https://cdn.tablera.com/project/homepage/asset/images/register-mobile.png', size: [350, 213.5] },
    },
  ];

  const tags = [
    { type: 'blue', value: 'Booking' },
    { type: 'green', value: 'Inviting' },
    { type: 'yellow', value: 'Tasting' },
    { type: 'red', value: 'Sharing' },
  ];
  const bubbles = [
    { className: 'bubble-left-1', value: '🥂' },
    { className: 'bubble-left-2', value: '‍👨‍💼' },
    { className: 'bubble-left-3', value: '‍👩‍💼' },
    { className: 'bubble-right-1', value: '🤵' },
    { className: 'bubble-right-2', value: '📈' },
    { className: 'bubble-right-3', value: '🧾' },
    { className: 'bubble-right-4', value: '‍👨‍🍳' },
  ];
  const onClick = () => {
    window.open('https://wenjuan.feishu.cn/m/cfm?t=sT6FlLpX2MGi-mlkp');
  };

  return (
    <div className={'module-register page-module'}>
      <div className={'module-register-shadow'}></div>

      <div className={'module-register-content 1lhstock-hidden'}>
        <Logo className={'module-register-content__logo'} width={44} height={44} size={'big'} hasMargin={true}></Logo>
        <p className={'module-register-content__des'}>
          <span className={'des-start'}>{t('register.explanation.start')}</span>
          <span className={'des-brand'}>{t('common.brand.name')}</span>
          <span className={'des-end'}>{t('register.explanation.end')}</span>
        </p>

        <div className={'module-register-content__tags'}>
          {tags.map((tag) => (
            <Tag {...tag} key={tag.value} />
          ))}
        </div>
        <Button className={'module-register-content__button'} size={mobile ? 'middle' : 'big'} onClick={onClick}>
          {t('common.btn.introducer')}
        </Button>
        <div className={'module-register-content__feat'}>
          {/* <div className={'module-register-content__feat__bubbles'}>
            <div className={'module-register-content__feat__bubbles-wrapper'}>
              {bubbles.map((bubble, i) => (
                <Bubble className={bubble.className} key={i}>
                  {bubble.value}
                </Bubble>
              ))}
            </div>
          </div> */}
          <Photo className={'page-module-img' + `${mobile ? ' mobile' : ' pc'}`} src={img[+mobile].home.url} />
        </div>
      </div>
    </div>
  );
}

export default (props: { title?: string; des?: string }) => (
  <PageModule {...props} children={<Register></Register>} overallWidth={true}></PageModule>
);
