import { useTranslation } from 'react-i18next';
import './index.scss';
import PageModule from '@/components/page-component';
import useMobile from '@/utils/isMobile';

function Price() {
  const { t } = useTranslation();
  const { mobile } = useMobile();
  return (
    <div className="module-price page-module">
      <div className="module-price-wrap">
        <div className="module-price-normal">
          <div className="module-price-title">预订解决方案</div>
          <div className="module-price-price">
            6000<span className="module-price-unit">元/年</span>
          </div>
          <div className="module-price-divider"></div>
          <ul className="module-price-info">
            <li className="module-price-info-item">餐厅小程序</li>
            <li className="module-price-info-item">预订管理系统</li>
            <li className="module-price-info-item">销售助手</li>
            <li className="module-price-info-item">...</li>
          </ul>
        </div>
        <div className="module-price-vip">
          <div className="module-price-title">定制解决方案</div>
          <div className="module-price-price">沟通后报价</div>
          <div className="module-price-divider"></div>
          <ul className="module-price-info">
            <li className="module-price-info-item">私域部署</li>
            <li className="module-price-info-item">店铺装修</li>
            <li className="module-price-info-item">定制功能</li>
            <li className="module-price-info-item">...</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
// export default Price;
export default (props: { title?: string; des?: string; className?: string }) => (
  <PageModule {...props} children={<Price></Price>}></PageModule>
);
