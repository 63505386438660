import LogoBrand from '@/components/logo-brand';
import Demo from '@/pages/home/components/demo';
import Register from '@/pages/home/components/register';
import MiniProgram from '@/pages/home/components/mini-program';
import Deposit from '@/pages/home/components/deposit';
import Client from '@/pages/home/components/client';
import Introducer from '@/pages/home/components/introducer';
import Footer from '@/pages/home/components/footer';
import useMobile from '@/utils/isMobile';
import Customer from './components/customer';
import Price from './components/price';
import Contact from './components/contact';

function Home() {
  const { mobile } = useMobile();
  return (
    <div className={`a-main-content${mobile ? ' mobile' : ' pc'}`}>
      <Register />
      <Customer className="a-module-customer" title="我们的客户" des="以下品牌已使用全新预订方式" />
      <MiniProgram className="a-module-mini-program" title="预订小程序" des="提供实时&高效的餐厅预订方式" />
      <Deposit className="a-module-deposit" title="餐厅预订管理" des="高效桌台预订管理&食客个性化服务" />
      <Client className="a-module-client" title="客户案例" />
      <Price className="a-module-price" title="价格" />
      <Introducer />
      <Footer className="a-module-footer" />
      <Contact />
    </div>
  );
}
export default Home;
