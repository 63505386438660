import {useMemo} from 'react';
import './index.scss';

interface IProps {
    size?: string;
    children?: string;
    className?: string;
    onClick?: () => void;
} ;

function Button(props: IProps) {
    const {size = 'middle', children, className, onClick} = props;
    const classname = useMemo(() => {
        let _className = ['a-button', `a-button__${size}`];
        if (className) {
            _className.push(className);
        }
        return _className
    }, [size, className]);
    return <button className={classname.join(' ')} onClick={onClick}>
        <p>{children}</p>
    </button>
}
export default Button;
