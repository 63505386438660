interface IProps {
  type: string;
  value: string;
}

function Tag(props: IProps) {
  const { type, value } = props;
  return (
    <div className={`register-tag register-tag-${type}`}>
      <div className="register-tag-ellipse"></div>
      <span className="register-tag-text">{value}</span>
    </div>
  );
}
export default Tag;
