import Nav from '@/components/nav';
import {MutableRefObject, ReactElement, useRef} from 'react';
import './index.scss'
interface IProps {
  children: ReactElement;
}
function Layout(props: IProps) {
  const { children } = props;
  return (
    <main className="main">
      <Nav></Nav>
      {children}
    </main>
  );
}
export default Layout;
