import Photo from '@/components/photo';
import PageModule from '@/components/page-component';
import FeatCard from '@/components/feat-card';
import { useTranslation } from 'react-i18next';
import './index.scss';
import useMobile from '@/utils/isMobile';
function MiniProgram() {
  const { mobile } = useMobile();

  // 0: pc 1: mobile;
  const img = [
    {
      bg: { url: 'https://cdn.tablera.com/project/homepage/asset/images/mini-program.png', size: [1020, 620] },
    },
    {
      bg: { url: 'https://cdn.tablera.com/project/homepage/asset/images/mini-program.png', size: [339, 206] },
    },
  ];
  const { t } = useTranslation();
  const data = new Array(4).fill('').map((d: string, i: number) => {
    return {
      title: t(`booking.card${i}.title`),
      des: t(`booking.card${i}.content`),
      iconType: i + 1,
    };
  });
  return (
    <div className={`module-mini-program page-module${mobile ? ' mobile' : ''}`}>
      <Photo className={'page-module-img'} src={img[+mobile].bg.url} />
      <div className={'feat-cards'}>
        <FeatCard data={data}></FeatCard>
      </div>
    </div>
  );
}
export default (props: { title?: string; des?: string; className?: string }) => (
  <PageModule {...props} children={<MiniProgram></MiniProgram>}></PageModule>
);
