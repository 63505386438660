import { ReactComponent as LogoIcon } from '@/images/logo.svg';
import { ReactComponent as LogoIconBig } from '@/images/logo-big.svg';
import { useState } from 'react';

interface IProps {
  size?: 'small' | 'big';
  width?: number;
  height?: number;
  className?: string;
  hasMargin?: boolean;
 }

function Logo(props: IProps) {
  const { size = 'small', width, height, hasMargin = false } = props;
  let classname = ['a-logo'];
  if (!(width && height)) {
    classname.push('a-logo__' + size);
  }
  if (props.className) {
    classname.push(props.className);
  }
  if (!hasMargin) {
    return <LogoIcon className={classname.join(' ')} width={width} height={height} />;
  }
  return <LogoIconBig className={classname.join(' ')} width={width} height={height} />;
}
export default Logo;
