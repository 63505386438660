import Photo from '@/components/photo';
import FeatCard from '@/components/feat-card';
import PageModule from '@/components/page-component';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import useMobile from '@/utils/isMobile';
function Deposit() {
  const { t } = useTranslation();
  const { mobile } = useMobile();
  // 0: pc 1: mobile;
  const img = [
    {
      deposit: {
        url: 'https://cdn.tablera.com/project/homepage/asset/images/deposit.png',
        size: [1038, 646],
      },
    },
    {
      deposit: {
        url: 'https://cdn.tablera.com/project/homepage/asset/images/deposit.png',
        size: [335, 205],
      },
    },
  ];

  const data = new Array(4).fill('').map((d: string, i: number) => {
    return {
      title: t(`deposit.card${i}.title`),
      des: t(`deposit.card${i}.content`),
      iconType: i + 5,
    };
  });
  return (
    <div className={'module-deposit page-module'}>
      <Photo className={'page-module-img'} src={img[+mobile].deposit.url} />
      <div className={'feat-cards'}>
        <FeatCard data={data}></FeatCard>
      </div>
    </div>
  );
}
export default (props: { title?: string; des?: string; className?: string }) => (
  <PageModule {...props} children={<Deposit></Deposit>}></PageModule>
);
