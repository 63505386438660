import { useCallback, useEffect, useState } from 'react';

const getMobile = () => {
  let isMobile = false;
  if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    // isMobile = true;
  }
  if (document.body.clientWidth < 1208) {
    isMobile = true;
  }
  return isMobile
};
export default function useMobile() {
  const [mobile, setMobile] = useState(false);
  const changeMobile = useCallback(() => {
    setMobile(() => getMobile());
  }, []);
  useEffect(() => {
    changeMobile();
    window.addEventListener('resize', changeMobile);
    return () => {
      window.removeEventListener('resize', changeMobile);
    };
  }, []);
  return { mobile };
}
