import { ReactComponent as Icon1 } from '@/images/icon-1.svg';
import { ReactComponent as Icon2 } from '@/images/screen.svg';
import { ReactComponent as Icon3 } from '@/images/clock.svg';
import { ReactComponent as Icon4 } from '@/images/watch.svg';
import { ReactComponent as Icon5 } from '@/images/icon-2.svg';
import { ReactComponent as Icon6 } from '@/images/praise.svg';
import { ReactComponent as Icon7 } from '@/images/chart.svg';
import { ReactComponent as Icon8 } from '@/images/bell.svg';
import './index.scss';
import { ReactElement } from 'react';

interface ICardProps {
  iconType?: number;
  title?: string;
  des?: string;
}
interface ICardsProps {
  data?: ICardProps[];
}
function FeatCard(props: ICardsProps) {
  const { data } = props;
  const Icons: { [key: string]: any } = {
    '1': Icon1,
    '2': Icon2,
    '3': Icon3,
    '4': Icon4,
    '5': Icon5,
    '6': Icon6,
    '7': Icon7,
    '8': Icon8,
  };
  return (
    <div className={'feat-card-wrapper'}>
      {data?.map((card, index) => {
        const Icon = Icons[card.iconType + ''];
        return (
          <div className={'feat-card'} key={index}>
            <Icon className={'feat-card-icon'}></Icon>
            <p className={'feat-card-title'}>{card.title}</p>
            <p className={'feat-card-des'}>{card.des}</p>
          </div>
        );
      })}
    </div>
  );
}

export default FeatCard;
