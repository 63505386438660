interface IProps {
  title?: string;
  des?: string;
}
function ModuleHeader(props: IProps) {
  const { title, des } = props;
  return (
    <div className={'a-section-header'}>
      {title && (
        <div className={'a-section-header__title'}>
          <div className={'a-section-header__title__line'}></div>
          <span className={'a-section-header__title__title'}>{title}</span>
          <div className={'a-section-header__title__line'}></div>
        </div>
      )}
      {des && (
        <div className={'a-section-header__des'}>
          <span>{des}</span>
        </div>
      )}
    </div>
  );
}

export default ModuleHeader;
