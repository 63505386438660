import { useMemo } from 'react';
import { mapSizeOfLogo } from '@/components/logo-brand/variable';
import './index.scss';
import { ReactComponent as IconLogo1 } from '@/images/brand-logo/property28.svg';
import { ReactComponent as IconLogo2 } from '@/images/brand-logo/property20.svg';
import { ReactComponent as IconLogo3 } from '@/images/brand-logo/property16.svg';
import { ReactComponent as IconLogo4 } from '@/images/brand-logo/property10.svg';
interface IProps {
  brandSize?: number; // 1: 28; 2: 20; 3: 16; 4: 10;
  onClick?: any;
}

function LogoBrand(props: IProps) {
  const { brandSize = 1 } = props;
  const mapIcon: { [key: string]: any } = {
    1: IconLogo1,
    2: IconLogo2,
    3: IconLogo3,
    4: IconLogo4,
  };
  const IconBrand = useMemo(() => {
    return mapIcon[`${brandSize}`];
  }, [brandSize]);

  return (
    <div className={'a-brand-name a-brand-name-' + brandSize} onClick={props.onClick}>
      <IconBrand></IconBrand>
    </div>
  );
}
export default LogoBrand;
