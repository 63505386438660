import './index.scss';
import { useTranslation } from 'react-i18next';
import useMobile from '@/utils/isMobile';
import Photo from '@/components/photo';

interface IProps {
  storeName?: string;
  storeLogo?: string;
  storeImg?: { url: string; size: number[] };
  desc?: string;
  honor?: string;
}
interface IStoresProps {
  data?: IProps[];
}
function StoreCard(props: IProps) {
  const { storeLogo, storeName, storeImg, desc, honor } = props;
  return (
    <div className={'store-card'}>
      <div className={'store-card-header'}>
        <div className={'store-card-header-top'}>
          <div className={'store-card-header-top__title__left'}>
            <img src={storeLogo} alt="商家logo" /> <p className={'store-card-header-name'}>{storeName}</p>
          </div>
          {honor && <img className={'store-card-header-honor'} src={honor} alt="" />}
        </div>
        <div className={'store-card-header-line'}></div>
        <div className={'store-card-header-bottom'}>
          <pre>{desc?.trim()}</pre>
        </div>
      </div>
      <div className={'store-card-content'}>
        <div className={'store-card-content__store'}>
          <Photo className={'store-card-content__store__img'} src={storeImg!.url} alt="商家logo" />
        </div>
      </div>
    </div>
  );
}

function StoreCards(props: IStoresProps) {
  const { t } = useTranslation();
  const { mobile } = useMobile();
  // 0: pc 1: mobile;
  const img = [
    {
      linglong: {
        url: 'https://cdn.tablera.com/project/homepage/asset/images/cover-linglong.png',
        size: [530, 456],
      },
      cumo: {
        url: 'https://cdn.tablera.com/project/homepage/asset/images/cover-cumo.png',
        size: [530, 456],
      },
    },
    {
      linglong: {
        url: 'https://cdn.tablera.com/project/homepage/asset/images/cover-linglong.png',
        size: [300, 270],
      },
      cumo: {
        url: 'https://cdn.tablera.com/project/homepage/asset/images/cover-cumo.png',
        size: [300, 270],
      },
    },
  ];

  const arrImg = [
    {
      storeName: 'LINGLONG 凌珑',
      storeLogo: 'https://cdn.tablera.com/project/homepage/asset/images/logo-linglong.png',
      storeImg: img[+mobile]['linglong'],
      honor: 'https://cdn.tablera.com/project/homepage/asset/images/honor-linglong.png',
      desc: `
上海凌珑餐厅「LING LONG」已入驻上海外滩华尔道夫酒店一层，携手这座走过百年的上海地标性建筑，一同续写关于摩登新中式「Re-fine Dining」的美食故事。以本土食材为笔，以鲜为色彩，绘制出一幅名为「LING LONG」的作品。

店内装饰源于儒道美学理念——“墨染其上，朱画其内”。红与黑的色彩与中国书法衍生的当代艺术所结合，构成一幅幅充满视觉冲击和遐想的屏风和装饰画作。正与LINGLONG主打的“融合”概念不谋而合。餐厅面积150平米，可容纳42个餐位含2间六至八人的独立用餐空间。
      `,
    },
    {
      storeName: '丘末茶研所',
      storeLogo: 'https://cdn.tablera.com/project/homepage/asset/images/logo-cumo.png',
      storeImg: img[+mobile]['cumo'],
      desc: `
丘未是一家以当代宋式美学为主题的新型茶艺术空间，以吧台模式为主导，提供集合了纯茶，盘饰茶点，以及茶酒于一身的六道式品饮体验。

宋人在流玉扬沫之中窥见万象世界。丘末以此精神为指引，冠茶研之名，用科学的态度去探索和拓宽茶的冲泡及品饮体验。

于吧台之上，铸建一个开放，静逸，融洽的社群氛團，寻回茶之于人的意义。

在这里，再一次打开和贯通五感，使情绪自然游走，交融，相会。
      `,
    },
  ];
  const data = [0, 1].map((num) => {
    return {
      storeAddress: t(`client.card${num}.brand.address`),
      storeDes: t(`client.card${num}.brand.des`),
      ...arrImg[num],
    };
  });
  return (
    <div className={'store-card-wrapper'}>
      {data.map((store, index) => (
        <StoreCard {...store} key={index}></StoreCard>
      ))}
    </div>
  );
}

export default StoreCards;
