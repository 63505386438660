import { ReactComponent as PhoneIcon } from '@/images/phone.svg';
import { ReactComponent as MailIcon } from '@/images/mail.svg';
import './index.scss';
import { useState } from 'react';

function Contact() {
  const [visible, setVisible] = useState(false);

  function copyMail() {
    // 创建输入框
    const textarea = document.createElement('textarea');
    document.body.appendChild(textarea);
    // 隐藏此输入框
    textarea.style.position = 'absolute';
    textarea.style.clip = 'rect(0 0 0 0)';
    // 赋值
    textarea.value = 'gary.gu@tablera.com';
    // 选中
    textarea.select();
    // 复制
    document.execCommand('copy', true);

    window.alert('复制成功');
  }

  return (
    <div className="module-contact">
      {visible && (
        <div className="module-contact-popover" onClick={() => setVisible(false)}>
          <div className="module-contact-popover-content" onClick={(e) => e.stopPropagation()}>
            <div className="module-contact-title">联系我们</div>
            <div className="module-contact-line">
              <MailIcon />
              <a href="mailto:gary.gu@tablera.com">gary.gu@tablera.com</a>
              <span
                className="module-contact-tag"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  copyMail();
                }}
              >
                复制
              </span>
            </div>
            <div className="module-contact-line">
              <PhoneIcon />
              <a href="tel:15001750001" type="tel">
                15001750001
                <span className="module-contact-tag">拨打</span>
              </a>
            </div>
          </div>
        </div>
      )}
      <div className="module-contact-btn" onClick={() => setVisible(!visible)}>
        <PhoneIcon />
        联系我们
      </div>
    </div>
  );
}

export default Contact;
