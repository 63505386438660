import { useTranslation } from 'react-i18next';
import PageModule from '@/components/page-component';
import LogoBrand from '@/components/logo-brand';
import Button from '@/components/button';
import './index.scss';
import useMobile from '@/utils/isMobile';

function Introducer() {
  const { t } = useTranslation();
  const { mobile } = useMobile();

  const onClick = () => {
    window.open('https://wenjuan.feishu.cn/m/cfm?t=sT6FlLpX2MGi-mlkp');
  };
  return (
    <div className={'module-introducer page-module'}>
      <div className={'module-introducer__content a-flex-box'}>
        <div className={'module-introducer__content__left'}>
          <div className={'module-introducer__content__left__top a-font-h4'}>
            <span className={'des-start'}>{t('introducer.explanation.start')}</span>
            <LogoBrand brandSize={mobile ? 3 : 1}></LogoBrand>
            <span className={'des-end'}>{t('introducer.explanation.end')}</span>
          </div>
          <div className={'module-introducer__content__left__bottom'}>
            <p className={'a-font-h6'}>{t('introducer.des')}</p>
          </div>
        </div>
        <div className={'module-introducer__content__right'}>
          <Button size={mobile ? 'small' : 'big'} onClick={onClick}>
            {t('common.btn.introducer')}
          </Button>
        </div>
      </div>
    </div>
  );
}
export default (props: { title?: string; des?: string }) => (
  <PageModule {...props} children={<Introducer></Introducer>}></PageModule>
);
