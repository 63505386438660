import PageModule from '@/components/page-component';
import StoreCards from '@/components/store-card';
import './index.scss';
function Deposit() {
  return (
    <div className={'module-client page-module'}>
      <StoreCards />
    </div>
  );
}
export default (props: { title?: string; des?: string; className?: string }) => (
  <PageModule {...props} children={<Deposit></Deposit>}></PageModule>
);
