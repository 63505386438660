import { ReactElement } from 'react';
import ModuleHeader from '@/components/page-component/header';
import './index.scss';

interface IProps {
  children?: ReactElement;
  title?: string;
  des?: string;
  overallWidth?: boolean;
  className?: string;
}
function PageModule(props: IProps) {
  const { children, title, des, overallWidth = false, className } = props;
  const classname = ['a-section'];
  if (className) {
    classname.push(className);
  }
  return (
    <section className={classname.join(' ')}>
      <div className={!overallWidth ? 'a-page-content' : ''}>
        {(title || des) && <ModuleHeader title={title} des={des}></ModuleHeader>}
        {children}
      </div>
    </section>
  );
}
export default PageModule;
