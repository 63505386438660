import LogoBrand from '@/components/logo-brand';
import { useTranslation } from 'react-i18next';
import './index.scss';
import PageModule from '@/components/page-component';
import useMobile from '@/utils/isMobile';

function Footer() {
  const { t } = useTranslation();
  const { mobile } = useMobile();

  return (
    <div className={'module-footer page-module'}>
      <div className={'module-footer__content a-flex-box'}>
        <div className={'module-footer__content__left'}>
          <div className={'module-footer__content__left__top a-flex-box'}>
            <LogoBrand brandSize={mobile ? 4 : 3}></LogoBrand>
            <span className={'des-end'}>{t('footer.explanation.text')}</span>
          </div>
          <div className={'module-footer__content__left__bottom'}>
            <p>{t('footer.record.text')}</p>
          </div>
        </div>
        <div className={'module-footer__content__right'}>
          <p>
            <span>{t('footer.contact.label')}：</span>
            <span>{t('footer.contact.value')}</span>
            <span className={'module-footer__content__right__slicer'}> / </span>
            <span>15001750001</span>
          </p>
        </div>
      </div>
    </div>
  );
}
// export default Footer;
export default (props: { title?: string; des?: string; className?: string }) => (
  <PageModule {...props} children={<Footer></Footer>}></PageModule>
);
